import React from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
// const footerDesc = "";

const Index = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <LaunchForm lang={appState.lang} title="Thanks for your interest! We are about to launch 🚀" subtitle="Request your invitation and you will be one of the first to know when we are ready." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const goFinancingHandler = () => {
        navigate("/en/financing/")
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="EVVA digital business account makes your money grow, 100% online" description="SMEs and Startups, Freelancers say goodbye to paperwork and long queues at the branch. With EVVA get your digital business account that makes your money grow, 100% online."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
            }
            <MainSection title="EVVA, the digital business account that makes your money grow." description="<h3>Goodbye paperwork and long lines at the branch. Get a digital business account that makes your money grow 100% online.</h3>" position="left" image="hero-home-en-v3.png" actionLabel="Open an account" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
                <AppBar pathname={appState.path} />
            </MainSection>
            <PageSection>
                <div style={{marginTop: "-120px", zIndex: "1"}}>
                    <Card
                        subtitle="Get financing for your business"
                        description="<p>We have joined forces with Uniclick by Unifin, leader in the financing market, which has funded thousands SMEs in México.</p>"
                        descriptionColor="#363636"
                        image="search-icon.svg"
                        imageWidth="240px"
                        imageHeight="160px"
                        actionLabel="Learn more"
                        action={goFinancingHandler}
                        actionVariant="outlined"
                        bgColor="#fff"
                        horizontal
                        padding
                        shadow
                    />
                </div>
            </PageSection>
            <SecondarySection
                title="Together we grow"
                shortDescription="From Entrepreneurs to entrepreneurs, we are people like you who understand your needs. Our ultimate goal is to let you focus on what really matters ... Your business."
                position="center"
                image="evva-together.jpg"
                actionLabel="Open an account"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            />
        </Layout>
    )
};

export default Index